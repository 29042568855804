<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
        <h3 class="end">
          {{$t('clime_description')}}
        </h3>
      </div>
      <div class="footer">
        <button
          class="sub"
          :class="{ 'sub-dis': isPay }"
          submit
          @click="subCommit"
        >
          {{ $t("popupTransferCommit") }}
        </button>
      </div>
    </v-card>

    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";

export default {
  data: () => ({
    newOpen: true,
    nftName: "",
    isShowFalse: false,
    isPay: false,
    IRISAddress: "",
    walletAddress: "",
  }),
  props: {
    baseInfo: {
      type: Object,
    },
    claimType: {
      type: String,
    },
	currentToken: {
      type: String,
    },
	currentType: {
      type: String,
    },



  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  mounted() {},
  methods: {
    //提交转送数据
    async subCommit() {
      this.isPay = true;

      let params = {
        // did:"iaa1r44svrd44syk7g79z3h53chg2ar624l8fc5ap5",
        id: "206",
      };
      if (this.claimType == "origin3")
	   {
		   params.id = "207";
		   
	   }else if(this.claimType ==  'uptickReward'){
		   params.id='209'
	   }
      let res = await api.home.applyPromo(params);
      console.log("applyPromo", res.data);

      if (res.msg) {
        this.$toast("success", this.$t("success")).then(() => {
          window.eventBus.$emit("claimSuccess");
          this.$emit("getOpen", false);
        });
      } else {
        this.$toast("error", this.$t("failed"));
        this.isPay = false;
      }
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 239px;
  width: 600px;

  .top {
    .end {
      margin: 68px 52px 0px;
      text-align: center;
      font-family: Helvetica;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #270645;
    }
  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    margin: 0 auto;

    .sub {
      margin: 30px auto;
      width: 450px;
      height: 51px;
      background-color: #270645;
      border-radius: 25px;
      opacity: 0.9;
      font-family: Helvetica;
      font-size: 20px;
      color: #ffffff;
    }
  }

  &.mobile {
    .footer {
      width: 100%;
      margin: 0;

      .sub {
        margin-left: 10%;
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}

.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}

.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 5px;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}
</style>
