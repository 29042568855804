import { render, staticRenderFns } from "./InterchainReward.vue?vue&type=template&id=67b7268a&scoped=true"
import script from "./InterchainReward.vue?vue&type=script&lang=js"
export * from "./InterchainReward.vue?vue&type=script&lang=js"
import style0 from "./InterchainReward.vue?vue&type=style&index=0&id=67b7268a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67b7268a",
  null
  
)

export default component.exports