<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="content d-flex flex-column flex-lg-row justify-center mb-16">
      <div class="left ml-lg-8 mr-lg-5">
        <div class="PromoImage">
          <img src="@/assets/promoImage/Uptick_c10.jpg" alt="" />
        </div>
      </div>
      <div class="right mt-lg-0 mt-9">
        <div class="titles mt-2 mb-lg-6">
          <span :class="{ darkFont: $vuetify.theme.dark }"
            >Claim Your Game of Interchain NFTs Reward</span
          >
        </div>
        <div class="desc mb-lg-6">
          <div class="font-15" :class="{ darkFont: $vuetify.theme.dark }">
            The Game of Interchain NFTs is a celebration and realization of the collective efforts and hard work invested in promoting the ICS-721 protocol. With the Uptick on IRISnet NFT Marketplace and the Uptick Chain NFT Marketplace now both fully supporting cross-chain functionality, we are bringing the previous visions and concepts into a tangible reality for our community, and connecting the dots between technological advancement and practical user engagement.
          </div>
         
          <div class="font-15 mt-6" :class="{ darkFont: $vuetify.theme.dark }">
            The reward claiming period for the Game of Interchain NFTs is now live! Please ensure that you have completed all the requirements of the event, and connect your participantion wallet account to check your rewards.
          </div>
          <div class="font-15 mt-6" :class="{ darkFont: $vuetify.theme.dark }">
            Please note that the event features two unique cross-chain games, each linked to its own marketplace. Participants are encouraged to join both games to earn double rewards. <span> And you can claim rewards for Game of Interchain NFTs | Game #2 on the current page. If you would like to claim rewards for Game #1,  <a href="https://uptick.upticknft.com/interchainReward" target="_blank" rel="noopener noreferrer">please click here.</a> </span> 
          </div>
        </div>

        <!-- <div class="imglist mt-6">
          <img src="@/assets/promoImage/promo11m.jpg" alt="" />
        </div> -->
        <div class="claimtime mb-lg-5 mt-6">
          <div>
            <span :class="{ darkFont: $vuetify.theme.dark }"
              >Claiming time: 2024.3.14 15:00 ~ 2024.4.14 15:00 (UTC+8)</span
            >
          </div>
        </div>
        <div class="connect" v-if="!this.$store.state.did">
          <button class="btn" @click="connectBtn()">Connect your wallet</button>
        </div>
        <div class="claimInfo mt-6" v-else>
          <div class="addressTitle">The currently connected wallet address is:</div>
          <div class="address">{{ this.$store.state.did }}</div>
          <div class="address">{{ uptickAddress }}</div>
          <div class="address">{{ bscAddress }}</div>
          
          <div v-if="isJurisdiction" >
            <div class="font-20 mt-6">You can claim: </div>
            <div class="d-flex flex-wrap">
              <div class="claimList  d-flex flex-lg-row flex-column mt-5 mr-8"  v-for="(item,index) in baseInfo" :key='index' >
              <div class="NFT">
                <div class="font-15 mt-2" v-if="item.grantType == 'ERC721' || item.grantType == 'ERC1155'">Interchain NFT Pioneer | Game #2</div>
                <div class="font-15 mt-2" v-if="item.grantType == 'NATIVE'||item.grantType == 'ERC20' ">Token Reward B</div>
                <img v-if="item.grantType == 'ERC721' || item.grantType == 'ERC1155'" src="@/assets/promoImage/Uptick_c12.jpg" alt="" />
                <div class="reward"  v-if="item.grantType == 'NATIVE' || item.grantType == 'ERC20'">{{item.grantAmount}} {{item.grantTokenAddress}}</div>

              </div>
              <div class="add ml-lg-6" v-if="index != baseInfo.length-1">+</div>
              <!-- <div class="NFT ml-lg-8 ">
                <div class="font-20"></div>
                <div class="font-15 mt-5">Token Reward B</div>
                <div class="reward">200 IRIS</div>
              </div> -->
            <!-- You can claim a total of : <span>{{baseInfo.grantAmount}}</span> {{baseInfo.grantCoin}} -->
          </div>
            </div>
          
          <div class="connect mt-5" v-if='!isReceive'>
            <button class="btn" @click="claimPoint">Claim</button>
          </div>
          <div class="notClaim mt-5" v-else>
            <button class="btn" >Received</button>
          </div>
          </div>
          <div class="notClaim mt-5" v-else>
           <div class="font-20">Sorry, the participation record of this address cannot be found.</div>
          </div>
        
        </div>
      </div>
    </div>
    <v-dialog v-model="openActive">
			<ActivityClaim v-if="openActive"  :currentToken="currentToken" :currentType = "currentType" claimType="uptickReward" @getOpen="Active"></ActivityClaim>
		</v-dialog>
  </div>
</template>

<script>
import api from "@/api";
import ActivityClaim from "@/components/popup/activityClaim.vue";
import {
		getAddress
	} from "../../../../src/keplr/wallet";
import{getEvmAddress} from '../../../../src/utils/helper'
let drawAtomStartTimeStamp, drawAtomEndTimeStamp;
export default {
  components: {ActivityClaim},
  data() {
    return {
      d1: "",
      d2: "",
      h1: "",
      h2: "",
      m1: "",
      m2: "",
      s1: "",
      s2: "",
      isPhotographyEnd: false,
      isStart: false,
      isTimeOut: true,
      PromoClaim: false,
      isSatisfy: false, // 是否可以领取
      isReceive: false,
      isEnding: false,
      uptickAddress: "",
      isJurisdiction:false,
      openActive:false,
      baseInfo:[],
      bscAddress:'',
      accountInfo:{},
      currentToken:'',
       currentType:''
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
    window.eventBus.$on('claimSuccess', this.claimSuccess);
   
    await this.initUser();
    window.eventBus.$on("isLoading", this.isLoading);
  
  },
  methods: {
   async isLoading() {
     await this.initUser();
    },
    claimSuccess(){
      this.initUser();
    },
    async initUser() {
      let params = {
      id:"209",
    }
   let res = await api.home.getPromoUser(params)
   console.log("res.data",res.data);
   this.baseInfo = res.data
   for (let i = 0; i < this.baseInfo.length; i++) {
      if(this.baseInfo[i].grantType == 'NATIVE'){
        this.currentToken = this.baseInfo[i].grantAmount
        this.currentType = this.baseInfo[i].grantTokenAddress
      }
    
   }
   if(res.msg == 'Claim'){
    // 领取过了
    this.isReceive = true

   }else{
    this.isReceive = false
   }
    if(res.data.length>0){
      this.isJurisdiction = true
    }else{
      this.isJurisdiction = false
    }
    this.accountInfo = await getAddress(0);
      if ( this.accountInfo) {
        this.uptickAddress =  this.accountInfo.address
      } 
      this.bscAddress = getEvmAddress( this.uptickAddress)
   
    },
  
    BuyNft() {
      window.open(
        "https://uptick.upticknft.com/collection/uptickpass",
        "_black"
      );
    },
    openPromoClaim(e) {
      this.PromoClaim = e;
    },
    connectBtn() {
      window.eventBus.$emit("isWallet");
    },
    async claimPoint() {
				this.openActive = true


			},
      Active(e) {
				this.openActive = e
			},
  },
};
</script>

<style lang="scss" scoped>
.contain {
  .content {
    margin-top: 50px;
    .left {
      width: 45%;
      display:flex;
      justify-content: center;
      // height: 550px;
      img {
        width: 470px;
        height: 690px;
        display: block;
        object-fit: contain;
        cursor: pointer;
      }
      .timer {
        // max-width:305px;
        // margin:17px auto 21px;
        justify-content: center;
        margin-top: 17px;
        .mao {
          font-family: Helvetica;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: 0px;
          color: #270645;
        }
        .bom {
          font-family: Helvetica;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #766983;
          text-align: center;
          margin-top: 7px;
        }
        span {
          width: 27px;
          height: 36px;
          display: inline-block;
          text-align: center;
          line-height: 36px;
          background-color: #270645;
          border-radius: 5px;
          font-family: Helvetica;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
      .endDesc {
        margin-top: 20px;
        color: red;
        text-align: center;
      }
      .btn {
        border-radius: 25px;
        text-align: center;
        margin-left: -90px;
        .notClaim {
          width: 276px;
          height: 51px;
          border-radius: 25px;
          background-color: #766983;
          font-family: Helvetica;
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 51px;
          letter-spacing: 0px;
          color: #ffffff;
        }

        .claim {
          width: 276px;
          height: 51px;
          border-radius: 25px;
          background-image: linear-gradient(#270645, #270645),
            linear-gradient(
              90deg,
              #d300fd 0%,
              #a439fe 26%,
              #7471ff 52%,
              #00fdcf 100%
            ),
            linear-gradient(#270645, #270645);
          background-blend-mode: normal, normal, normal;

          font-family: Helvetica;
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 51px;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
    .right {
      width: 55%;
      .font-20{
            font-family: Helvetica;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #270645;
          display: inline-block;
          }
      // height: 613px;
      .claimList{
        .add{
          font-family: Helvetica;
      font-size: 46px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 50px;
      letter-spacing: 0px;
      color: #270645;
      display: flex;
      align-items: center;
      padding-top: 30px;
        }
        .NFT{
         
          .font-15{
            font-family: Helvetica;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #270645;
          }
          img{
            width: 130px;
            height: 130px;
            margin-top:16px
          }
          .reward{
            font-family: Helvetica;
              font-size: 35px;
              font-weight: bold;
              font-stretch: normal;
              line-height: 40px;
              color: #7800f4;
            margin-top:16px;
            height: 130px;
            display: flex;
    align-items: center;
            

          }
        }
      }
      .titles{
        span {
          font-family: Helvetica;
          font-size: 45px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 45px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .desc {
        .celebrate {
          width: 620px;
          font-family: Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 23px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .desc {
        .font-15 {
          max-width: 640px;
          font-family: Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 23px;
          letter-spacing: 0px;
          color: #270645;
          span{
            font-weight: bold !important;
            color:#7800f4 !important
          }
          a{
            font-weight: bold !important;
            color:#7800f4 !important
          }
        }
        .font-15-pop{
          max-width: 640px;
          font-family: Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 23px;
          letter-spacing: 0px;
          color: #7800f4;
        }
      }
      .claimInfo {
        .addressTitle {
          font-family: Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #270645;
        }
        .address {
          font-family: Helvetica;
          font-size: 15px;
          line-height: 25px;
          font-weight: normal;
          letter-spacing: 0px;
          color: #270645;
        }
        .amount {
          font-family: Helvetica;
          font-size: 22px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 30px;
         
          color: #270645;
          span {
            color: #af2ef1 !important;
          }
        }
      }
      .connect {
        .btn {
          width: 275px;
          height: 51px;
          background-color: #270645;
          border-radius: 25px;
          font-family: Helvetica;
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
      .notClaim{
        .btn {
          width: 275px;
          height: 51px;
          background-color:#766983;
          border-radius: 25px;
          font-family: Helvetica;
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: 0px;
          color: #ffffff;
        }

      }
      .claimtime {
        span {
          font-family: Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }

        div {
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 18px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .font-22 {
        font-family: Helvetica;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 25px;
        letter-spacing: 0px;
        color: #270645;
      }
      .font-15 {
        font-family: Helvetica;
        font-size: 15px;
        font-weight: normal;
        letter-spacing: 0px;
        color: #270645;
      }
      .imglist {
        img {
          width: 175px;
          object-fit: contain;
        }
      }
      .formore {
        margin-top: 38px;
        a {
          font-family: Helvetica;
          font-style: italic;
          text-decoration: underline;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #7800f4;
        }
      }
    }
  }
  &.mobile {
    .content {
      margin-top: 30px;
      .left {
        width: 100%;
        min-height: 437px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: contain;
          cursor: pointer;
        }
        .timer {
          // width:90%;
          // margin-left:14%;
          justify-content: center;
          margin-top: 17px;
          span {
            width: 20px;
            height: 30px;
            font-size: 15px;
            line-height: 30px;
          }
        }
        .endDesc {
          width: 340px;
          margin-left: 7px;
          margin-top: 20px;
          color: red;
        }
        .btn {
          width: 80%;
          .claim {
            width: 80%;
          }
        }
      }
      .right {
        width: 100%;
        min-height: 613px;
        .titles {
          margin-bottom: 34px;
          span {
            font-size: 25px;
            line-height: 40px;
          }
        }
        .desc {
          // max-width:576px;
          margin-bottom: 28px;
        }

        .claimtime {
          margin-bottom: 34px;
        }
        .connect {
          align-items: center;
          display: flex;
          justify-content: center;
        }
        .imglist {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
